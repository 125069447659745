import plural from "plural-ru";

export const pluralize = (number = 0, forms = []) => {
    return plural(number, ...forms);
};

export const formatNumber = (number) => {
    const formatter = new Intl.NumberFormat("ru-RU", {style: "decimal"});
    return formatter.format(number);
};
