import {StaticImage} from "gatsby-plugin-image";
import PropTypes from "prop-types";
import {useCallback} from "react";
import * as React from "react";
import {pluralize} from "../../utils/number";
import Section from "../Layout/Section";
import TemplateString from "../Common/TemplateString";
import Button from "../UI/Button";
import * as heroStyles from "./hero.module.css";

const SecurityHero = ({title, description, freeDaysCount}) => {
    const scrollToForm = useCallback(() => {
        const form = document.querySelector("#free-demo-form");
        if (!form) {
            return;
        }

        form.scrollIntoView({behavior: "smooth"});
    }, []);

    return (
        <Section>
            <div className={heroStyles.securityHero}>
                <h1 className={heroStyles.securityHero__title}>
                    <TemplateString template={title} />
                </h1>
                <p className={heroStyles.securityHero__desc}>{description}</p>
                <Button type="button" variant="primary" onClick={scrollToForm}>
                    {pluralize(freeDaysCount, ["%d день", "%d дня", "%d дней"])} бесплатно
                </Button>
                <StaticImage
                    src="../../assets/images/security/mobile.png"
                    alt=""
                    placeholder="none"
                    layout="fixed"
                    loading="eager"
                    className={`${heroStyles.securityHero__gradient} ${heroStyles.securityHero__gradient_mobile}`}
                />
                <StaticImage
                    src="../../assets/images/security/tablet.png"
                    alt=""
                    placeholder="none"
                    layout="fixed"
                    loading="eager"
                    className={`${heroStyles.securityHero__gradient} ${heroStyles.securityHero__gradient_tablet}`}
                />
                <StaticImage
                    src="../../assets/images/security/desktop.png"
                    alt=""
                    placeholder="none"
                    layout="fixed"
                    loading="eager"
                    className={`${heroStyles.securityHero__gradient} ${heroStyles.securityHero__gradient_desktop}`}
                />
            </div>
        </Section>
    );
};

SecurityHero.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    freeDaysCount: PropTypes.number.isRequired,
};

export default SecurityHero;
