import * as React from "react";
import {graphql} from "gatsby";
import Layout from "../components/Layout/layout";
import Seo from "../components/SEO/SEO";
import Capabilities from "../components/Tour/Capabilities";
import SecurityHero from "../components/Security/Hero";
import FreeDemoForm from "../components/Form/FreeDemo";

const SecurityPage = ({data: {pageData}}) => {
    const {list, seo} = pageData;

    return (
        <Layout>
            {seo && (
                <Seo
                    title={seo.title}
                    description={seo.description}
                    ogTitle={seo.openGraph?.title}
                    ogDescription={seo.openGraph?.description}
                    image={seo.openGraph?.image.length > 0 ? seo.openGraph?.image[0].url : ""}
                />
            )}
            <SecurityHero title={list.title} description={list.description} freeDaysCount={14} />
            <Capabilities column={2} description="" capabilityItems={list.list} />
            <FreeDemoForm />
        </Layout>
    );
};

export default SecurityPage;

export const pageQuery = graphql`
    query {
        pageData: strapiSecurity {
            seo {
                title
                description
                openGraph {
                    title
                    description
                    image {
                        url
                    }
                }
            }
            list {
                title
                description
                list {
                    id
                    title
                    description
                    icon {
                        mime
                        width
                        height
                        localFile {
                            publicURL
                            childImageSharp {
                                gatsbyImageData
                            }
                            svg {
                                content
                            }
                        }
                    }
                }
            }
        }
    }
`;
