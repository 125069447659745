import classNames from "classnames";
import * as React from "react";
import Section from "../Layout/Section";
import Media from "../Common/Media";
import TemplateString from "../Common/TemplateString";
import * as capabilitiesStyles from "./capabilities.module.css";

const Capabilities = ({sectionTitle, description, capabilityItems, column = 3}) => {
    return (
        <Section id="capabilities" title={sectionTitle} desc={<TemplateString template={description} />}>
            <div
                className={classNames({
                    [capabilitiesStyles.capabilities__list]: true,
                    [capabilitiesStyles.capabilities__list_size_small]: column === 2,
                })}
            >
                {capabilityItems.map(({id, title, description: itemDescription, icon}) => {
                    return (
                        <div key={id}>
                            <Media
                                media={{content: icon, youtubeVideoURL: ""}}
                                className={capabilitiesStyles.capabilities__itemIcon}
                            />
                            <h2 className={capabilitiesStyles.capabilities__itemTitle}>{title}</h2>
                            <p className={capabilitiesStyles.capabilities__itemDesc}>
                                <TemplateString template={itemDescription} />
                            </p>
                        </div>
                    );
                })}
            </div>
        </Section>
    );
};

export default Capabilities;
