import {StaticImage} from "gatsby-plugin-image";
import * as React from "react";
import {useCallback, useState} from "react";
import Logo from "../../assets/svg/logo.svg";
import Section from "../Layout/Section";
import CheckboxField from "./Fields/Checkbox";
import Select from "./Fields/Select";
import Input from "./Fields/Input";
import StatusModal from "./StatusModal";
import Form from "./index";
import Link from "../UI/Link";
import * as freeDemoFormStyles from "./free-demo.module.css";

const fields = [
    {
        name: "name",
        render({control, register, error, key}) {
            const rules = {
                minLength: {
                    value: 3,
                    message: "Имя должно быть не короче 2-ух символов",
                },
            };

            return (
                <Input
                    register={register}
                    control={control}
                    name={this.name}
                    placeholder="Как вас зовут?"
                    required="Имя обязательно к заполнению"
                    rules={rules}
                    error={error}
                    key={key}
                />
            );
        },
    },
    {
        name: "company",
        render({control, register, error, key}) {
            return (
                <Input
                    register={register}
                    control={control}
                    name={this.name}
                    placeholder="Название вашей компании"
                    required="Название компании обязательно к заполнению"
                    error={error}
                    key={key}
                />
            );
        },
    },
    {
        name: "phone",
        render({register, control, error, key}) {
            const rules = {
                pattern: {
                    value: /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}/i,
                    message: "Номер телефона должен соответствовать шаблону +7 (123) 456-78-90",
                },
            };

            return (
                <Input
                    register={register}
                    control={control}
                    type="tel"
                    name={this.name}
                    placeholder="Ваш номер телефона"
                    required="Номер телефона обязателен к заполнению"
                    rules={rules}
                    error={error}
                    key={key}
                />
            );
        },
    },
    {
        name: "email",
        render({control, register, error, key}) {
            const rules = {
                pattern: {
                    value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i,
                    message: "Введен некорректный email адрес",
                },
            };

            return (
                <Input
                    register={register}
                    control={control}
                    type="email"
                    name={this.name}
                    placeholder="Ваш email"
                    required="Email обязателен к заполнению"
                    rules={rules}
                    error={error}
                    key={key}
                />
            );
        },
    },
    {
        name: "automate",
        render({register, error, key, getValues, setValue}) {
            const options = [
                {
                    value: "ИТ-процессы внутри компании",
                },
                {
                    value: "ИТ-аутсорсинговую компанию",
                },
                {
                    value: "Сервисное обслуживание клиентов - юридических лиц",
                },
                {
                    value: "Внутренние службы (АХО, HR, Юристы и др.)",
                },
                {
                    value: "Поддержку клиентов - физических лиц",
                },
            ];

            return (
                <Select
                    getValues={getValues}
                    setValue={setValue}
                    placeholder="Что вы планируете автоматизировать?"
                    register={register}
                    name="automate"
                    key={key}
                    error={error}
                    options={options.map(({value}) => ({value, label: value}))}
                />
            );
        },
    },
    {
        name: "privacy",
        render({register, error, key}) {
            const label = (
                <span>
                    Я принимаю условия{" "}
                    <Link to="/privacy" withArrow={false}>
                        Политики в отношении обработки персональных данных
                    </Link>
                </span>
            );

            return (
                <CheckboxField
                    register={register}
                    label={label}
                    name={this.name}
                    required="Согласие с политикой в отношении обработки персональных данных обязательно"
                    error={error}
                    key={key}
                />
            );
        },
    },
];

const FreeDemoForm = () => {
    const [showStatusModal, setStatusModal] = useState(false);
    const [formStatus, setFormStatus] = useState("");

    const closeStatusModal = () => {
        setStatusModal(false);
    };

    const scrollToForm = useCallback(() => {
        const form = document.querySelector("#free-demo-form");
        if (!form) {
            return;
        }

        form.scrollIntoView({alignToTop: true, behavior: "smooth"});
    }, []);

    const handler = useCallback(
        async (data) => {
            const requestData = {
                title: "Попробуйте ITSM 365 бесплатно в течение 14 дней",
                department: "sales",
                fields: [
                    {
                        label: "Страница",
                        value: window.location.href,
                    },
                    {
                        label: "Как вас зовут?",
                        value: data.name,
                    },
                    {
                        label: "Название вашей компании",
                        value: data.company,
                    },
                    {
                        label: "Ваш номер телефона",
                        value: data.phone,
                    },
                    {
                        label: "Ваш email",
                        value: data.email,
                    },
                    {
                        label: "Что вы планируете автоматизировать?",
                        value: data.automate,
                    },
                ],
            };
            const response = await fetch(`${process.env.GATSBY_API_URL}/mailer/send`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                body: JSON.stringify(requestData),
            });
            const json = await response.json();

            setStatusModal(true);
            scrollToForm();
            if (!json.success) {
                console.error("Error");
                setFormStatus("error");

                return;
            }

            setFormStatus("success");
        },
        [scrollToForm],
    );

    return (
        <Section id="free-demo-form" containerClassName={freeDemoFormStyles.freeDemoForm__container}>
            {!showStatusModal && (
                <div className={freeDemoFormStyles.freeDemoForm}>
                    <div className={freeDemoFormStyles.freeDemoForm__info}>
                        <div>
                            <span className={freeDemoFormStyles.freeDemoForm__kicker}>ДЕМОНСТРАЦИЯ ПРОДУКТА</span>
                            <h2 className={freeDemoFormStyles.freeDemoForm__title}>
                                Узнайте, как ITSM 365 решит ваши задачи
                            </h2>
                            <p className={freeDemoFormStyles.freeDemoForm__desc}>
                                Запишитесь на бесплатную демонстрацию возможностей продукта
                            </p>
                        </div>
                        <Logo className={freeDemoFormStyles.freeDemoForm__logo} />
                    </div>
                    <div className={freeDemoFormStyles.freeDemoForm__formWrapper}>
                        <h4 className={freeDemoFormStyles.freeDemoForm__formTitle}>
                            Попробуйте ITSM 365 бесплатно в течение 14 дней
                        </h4>
                        <Form onSubmit={handler} fields={fields} />
                    </div>
                    <div className={freeDemoFormStyles.freeDemoForm__bgContainer}>
                        <StaticImage
                            src="../../assets/images/form/free-demo/desktop.png"
                            alt=""
                            placeholder="none"
                            className={`${freeDemoFormStyles.freeDemoForm__gradient} ${freeDemoFormStyles.freeDemoForm__gradient_desktop}`}
                        />
                        <StaticImage
                            src="../../assets/images/form/free-demo/tablet.png"
                            alt=""
                            placeholder="none"
                            className={`${freeDemoFormStyles.freeDemoForm__gradient} ${freeDemoFormStyles.freeDemoForm__gradient_tablet}`}
                        />
                        <StaticImage
                            src="../../assets/images/form/free-demo/mobile.png"
                            alt=""
                            placeholder="none"
                            className={`${freeDemoFormStyles.freeDemoForm__gradient} ${freeDemoFormStyles.freeDemoForm__gradient_mobile}`}
                        />
                    </div>
                </div>
            )}{" "}
            {showStatusModal && (
                <StatusModal
                    onClose={closeStatusModal}
                    status={formStatus}
                    className={freeDemoFormStyles.freeDemoForm__statusModal}
                />
            )}
        </Section>
    );
};

export default FreeDemoForm;
